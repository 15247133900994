<template>
    <div class="type-box" v-loading="loading">
        <div class="tp_list" v-for="(item,index) in list" :key="index">
            <el-tag
            closable
            :disable-transitions="false"
            @close="handleClose(item,index)">
            {{item.name}}
            </el-tag>
        </div>
        <div class="tp_list">
            <el-input
                class="input-new-tag"
                v-if="inputVisible"
                v-model="inputValue"
                ref="saveTagInput"
                size="small"
                @input="input"
                @keyup.enter.native="handleInputConfirm"
                @blur="handleInputConfirm"
                >
            </el-input>
            <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 添加</el-button>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        list:{
            type:Array,
            value:[]
        }
    },
    data(){
        return{
            inputValue:"",
            loading:false,
            inputVisible:false,
        }
    },methods:{
        input(){
            this.$forceUpdate()
        },
        //删除标签相应的类型，等级/区域
        async handleClose(e,index){
            //console.log(e)
            if(e.id){
                //有id则删除
                let params = {
                    method:'delGoodsType',
                    id:e.id
                }
                this.loading = true
                this.$ajax.post(this.API.api,params).then(res=>{
                    console.log(res)
                    this.loading = false
                    this.$message({
                        message:res.msg||this.CONST.DEL_SUC,
                        type:'success'
                    })
                    this.list.splice(index,1)
                    //告诉父组件更新数据
                    this.$emit("refresh",{type:this.type,list:this.list})
                }).catch(err=>{
                    console.log(err)
                    this.loading = false
                    this.$message({
                        message: err.msg?err.msg:err,
                        type: 'warning'
                    });
                })
            }else{
                this.list.splice(index,1)
            }
        },
        //提交数据
        handleInputConfirm(){
            let inputValue = this.inputValue;
            console.log(inputValue)
            if (inputValue&&inputValue!='') {
                let params = {
                    method:'addGoodsType',
                    name:inputValue
                }
                this.loading = true
                this.$ajax.post(this.API.api,params).then(res=>{
                    this.loading = false
                    //添加成功再添加到本地数据
                    this.list.push({
                        name:inputValue,
                        value:inputValue
                    });
                    this.$message({
                        message:res.msg||this.CONST.ADD_SUC,
                        type:'success'
                    })
                    //告诉父组件更新数据
                    this.$emit("refresh",{type:this.type,list:this.list})
                }).catch(err=>{
                    this.loading = false
                    this.$message({
                        message: err.msg?err.msg:err,
                        type: 'warning'
                    });
                })
                
            }
            this.inputVisible = false;
            this.inputValue = '';
            
        },
        showInput(){
            this.inputVisible = true
            this.$nextTick(()=> {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        }
    }
}
</script>
<style lang="less">
@import "../assets/style/drawer_type.less";
</style>