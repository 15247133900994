<template>
    <div class="i-box">
        <!-- upload-box -->
        <el-upload
            v-if="step==0"
            class="upload-demo"
            :action="url"
            :on-success="onSuccess"
            :data="form"
            :on-error="errorInfo"
            :file-list="fileList">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">{{helpInfo}}</div>
        </el-upload>
            <el-button  v-if="step==0" size="small" @click="downloadModule" type="warning" icon="el-icon-download" round>下载模板</el-button>
        <div class="table-data" v-if="step==1||step==2">
            <el-table
            :data="list[page-1]"
            :key="key"
            style="width: 100%">
            <el-table-column
                prop="num"
                label="编号"
                width="180">
            </el-table-column>
            <el-table-column
                prop="cn_name"
                label="名称">
            </el-table-column>
            <el-table-column
                prop="price"
                label="单价">
                <template slot-scope="scope">
                    {{scope.row.price}}元/{{scope.row.unit}}
                </template>
            </el-table-column>
            <el-table-column
                prop="address"
                label="状态">
                <template slot-scope="scope">
                    <van-loading  v-if="!scope.row.status"/>
                    <i class="el-icon-check" v-if="scope.row.status==1"/>
                    <i class="el-icon-close" v-if="scope.row.status==2" style="color:#CD5C5C"/>
                    <font v-if="scope.row.status==2" color="#CD5C5C">{{scope.row.err}}</font>
                </template>
            </el-table-column>
            </el-table>
            <!-- 状态 -->
            <div class="progress">
                <el-progress status="warning" :percentage="percent" :text-inside="true" :stroke-width="16"></el-progress>
            </div>
            <div class="btn-box">
                <div class="text">{{msg}}</div>
            </div>
            <div class="btn-box" v-if="step==2||step==1">
                <el-pagination
                :page-size="pageSize"
                :current-page="page+1"
                layout="prev, pager, next"
                @current-change="currentChange"
                :page-count="totalPage">
                </el-pagination>
            </div>
            <div class="btn-box" v-if="step==2">
                 <el-button type="success" round @click="close">关闭窗口</el-button>
                 <el-button type="info" round @click="exportList" plain
                  icon="el-icon-right" size="mini">导出结果列表</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import {exportExcel} from '@/utils/excel.js'
import {accDiv} from '@/utils/caculate.js'
import Vue from 'vue';
import { Loading } from 'vant';
Vue.use(Loading);
export default {
    name:"ImportClient",
    data(){
        return{
            page:1,
            key:0,
            pageSize:5,
            percent:0,
            current:0,
            url:this.API.api,
            fileList:[],
            dataList:[],//读取到的数据表
            list:[],//分页数据
            totalPage:0,
            msg:'',
            helpInfo:"请使用模板填入数据后导入，不超过1万条数据",
            form:{
                method:"importGoodsList"
            },
            user:{},
            step:0//步骤s
        }
    },
    created(){
        this.user = JSON.parse(sessionStorage.getItem('user'))
        this.url=this.url+'?token='+this.user.token
        console.log(this.url)
    },
    methods:{
        //下载模板
        downloadModule(){
            let url = "https://admin.yhipm.cn/admin/formwork/goods_import.xlsx"
            window.open(url,"blank")
        },
        //关闭窗口
        close(){
            this.$emit('close')
        },
        //导出结果列表
        exportList(){
            let columnTitle=['编号','中文','英文','单价','单位','成分','配比','使用方式','备注','单位规格转换','类别','状态']
            let columnData = []
            console.log(this.list)
            this.list.forEach(item=>{
                item.forEach(it=>[
                    columnData.push([it.num,it.cn_name,it.en_name,it.price,it.unit,it.composition,it.ratio,it.usage_type,it.remarks,it.specification,it.classification,it.status==2?it.err:it.status==1?'已导入':'未导入'])
                ])
            })
            
            exportExcel(columnTitle, columnData, "物料数据导入明细");
        },
        //上传
        async startUpload(){
            let list = this.list
            try {
                let res = await this.addGoods()
                if(res.status==0){
                    //console.log('添加成功')
                    list[this.page-1][this.current].status = 1
                }else{
                    list[this.page-1][this.current].status = 2
                }
            } catch (error) {
                list[this.page-1][this.current].status = 2
                list[this.page-1][this.current].err = error.msg
            }
            this.key=this.page+this.current
            this.list = list 
            //上传下一个
            if(this.current<(this.list[this.page-1].length-1)){
                this.current++
            }else{
                //下一页
                if(this.page<this.list.length){
                    this.page ++;
                    this.current = 0
                    this.percent = Math.ceil(this.page/this.totalPage * 100)
                }else{
                    //上传完成
                    this.step=2
                    this.msg = '数据导入完成！'
                    return false
                }
                
            }
            console.log('上传'+this.page+'页，第'+this.current+'个',this.page)
           //
           this.startUpload()
            //console.log(this.list[this.page-1][this.current])
        },
        currentChange(page){
            console.log(page)
            this.page = page
        },
        //添加客户到数据库
        async addGoods(){
            return new Promise((resolve,reject)=>{
                let params = this.list[this.page-1][this.current]
                this.loading = true
                params.loading = true
                params.method = "addGoods"
                params.update = true
                this.$ajax.post(this.API.api,params).then(res=>{
                    //todo
                    resolve(res)
                }).catch(err=>{
                    //err
                    reject(err)
                })
            })
        },
        onSuccess(response){
            if(response.status==0&&response.data){
                //获取到数据
                this.$message({
                    message: response.msg,
                    type: 'success'
                });
                this.msg = response.msg;
                this.dataList = response.data 
                this.step = 1
                this.initData()
                //console.log(this.dataList)
            }else{
                //失败了
                this.$message({
                    message: response.msg||'上传失败！',
                    type: 'warning'
                });
            }
        },
        //根据单位转换单价
        getPriceByspecification(item){
            //1*件/20*瓶/500*g
            let specification=item.specification,price=item.price,unit=item.unit,pArr=[] 
                if(!specification){
                    return false
                }
                let arr = specification.split("/")
                if(arr.length<=0){
                    return false
                }
                let err = false,current=''
                arr.forEach((item,index)=>{
                    let a = item.split("*")
                    a.length!=2?err = true:''
                    if(unit==a[1]){
                        current = index
                        unit=a[1]
                    }
                    pArr.push({
                        scale:a[0],
                        unit:a[1]
                    })
                })
                console.log(current)
                if(err||!current){
                    return false
                }
                //转换成最小单位的价格
                let scale=1
                for(var i=current+1;i<pArr.length;i++){
                    scale = scale * pArr[i].scale
                }
                return {
                    price:accDiv(price,scale),
                    unit:pArr[pArr.length-1].unit
                }
                
        },
        //将data数据按页码分出
        initData(){
            let that=this,start=0,end = this.pageSize,page=0,list=[],price=''
            this.dataList.forEach((item,index)=>{
                price = that.getPriceByspecification(item)
                if(price){
                    item.price = price.price 
                    item.unit = price.unit
                }
                if(index<end&&index>=start){
                    //
                    !list[page]?list[page]=[]:''
                    item.status = 0
                    item.err = ''
                    list[page].push(item)
                }
                if((index+1)%this.pageSize==0){
                    //下一页
                    start = index+1;
                    end = start + this.pageSize;
                    page++;
                }
            })
            this.list = list 
            this.totalPage = this.list.length
            console.log(list)
            // console.log(page)
            setTimeout(()=>{
                this.msg = "正在上传数据"
            },500)
            //上传
            this.startUpload()
        },
        errorInfo(response){
            console.log(response)
            this.$message({
                    message: response.msg||'上传失败！',
                    type: 'warning'
                });
        }
    }
}
</script>
<style lang="less">
.el-icon-check{
    font-size:20px;
    color: rgb(37, 168, 70);
}
.progress{
    padding:10px 20px;
}
</style>