<template>
     <div class="add-task-box add-cert" >
        <el-form v-loading="loading" ref="form" :model="form" label-width="80px" :rules="rules">
            <el-form-item label="物品图片">
               <!-- upload -->
                <el-upload
                    v-loading="loading"
                    class="avatar-uploader"
                    :on-error="uploadFail"
                    :before-upload="imgChange"
                    :on-success="uploadSuccess"
                    :data="jsonData"
                    :action="actionUrl"
                    :show-file-list="false">
                    <img v-if="form.w_logo" :src="form.w_logo" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
               <!-- upload -->
            </el-form-item>
            <el-form-item label="物品名称" class="demo-form-inline" required prop="cn_name">
                <el-input v-model="form.cn_name"  @change="change" @input="inputCnName"
                placeholder="中文名称"></el-input>
            </el-form-item>
            <el-form-item label="英文名称" class="demo-form-inline" required prop="en_name">
                <el-input v-model="form.en_name" @input="inputEnName"
                placeholder="英文名称"></el-input>
            </el-form-item>
            <el-form-item label="物品编号" class="demo-form-inline" required prop="num">
                <el-input v-model="form.num" @input="inputNum"
                placeholder="物品编号"></el-input>
            </el-form-item>
            <el-form-item label="单价单位" class="demo-form-inline" required prop="price">
                <el-input-number v-model="form.price" @change="priceHandleChange" :min="0" :max="50000" label="物品单价"></el-input-number>
                <i style="opacity:0"> - </i>
                 <el-select v-model="form.unit" placeholder="单位" @change="unichange">
                    <el-option
                    v-for="item in unitList"
                    :key="item.value"
                    :label="item.value"
                    :value="item.value">
                    </el-option>
                </el-select>
                <i style="opacity:0"> - </i>
                <el-button size="mini"  @click="editTy(0)" icon="el-icon-edit" type="primary" plain >单位</el-button>
            </el-form-item>
            <el-form-item label="类型" required prop="classification">
                 <el-select v-model="form.classification" placeholder="物品类型">
                    <el-option
                    v-for="item in typeList"
                    :key="item.value"
                    :label="item.value"
                    :value="item.value">
                    </el-option>
                </el-select>
                <i style="opacity:0"> - </i>
                <el-button size="mini" @click="editTy(1)" icon="el-icon-edit" type="primary" plain >类型</el-button>
                
            </el-form-item>
            <el-form-item label="使用方式" class="demo-form-inline">
                <el-select v-model="form.usage_type" placeholder="使用方式">
                    <el-option
                    v-for="item in usageList"
                    :key="item.value"
                    :label="item.value"
                    :value="item.value">
                    </el-option>
                </el-select>
                <i style="opacity:0"> - </i>
                <el-button size="mini"  @click="editTy(2)" icon="el-icon-edit" type="primary" plain >使用方式</el-button>
            </el-form-item>
            <el-form-item label="规格转换" class="demo-form-inline" prop="specification">
                <el-col :span="11">
                <el-input v-model="form.specification" 
                placeholder="1*件/20*瓶/500*g" width="120px"></el-input>
                </el-col>
                <el-col :span="11">
                 <i style="opacity:0"> - </i>
                <el-popover
                    placement="top-start"
                    title="什么是规格转换？"
                    width="200"
                    trigger="hover"
                    :content="pop">
                    <el-button icon="el-icon-question" circle slot="reference"></el-button>
                </el-popover>
                </el-col>
            </el-form-item>
            <el-form-item label="物品成分" class="demo-form-inline">
                <el-input v-model="form.composition" 
                placeholder="物品成分"></el-input>
            </el-form-item>
            <el-form-item label="物料配比" class="demo-form-inline">
                <el-input v-model="form.ratio" 
                placeholder="物料配比"></el-input>
            </el-form-item>
            <el-form-item label="备注">
                 <el-input type="textarea" v-model="form.remarks" maxlength="100"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">
                    提交</el-button>
                    <el-button @click="resetForm('form')">重置</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
import vPinyin from '@/utils/py.js';
export default {
    props:{
        updateId:{
            type:String,
            validate:""
        },
        usageList:{
            type:Array,
            value:[]
        },
        unitList:{
            type:Array,
            value:[]
        },
        typeList:{
            type:Array,
            value:[]
        }
    },
    watch:{
        updateId:{
            immediate: true,
            handler(updateId){
                //重置表单
                //this.$refs['form'].resetFields()
                if(updateId!=""){
                    //如果
                    this.form = {}
                    this.form.wid = parseInt(updateId)
                    //获取数据
                    this.getDetail(updateId)
                }else{
                    this.resetForm()
                    //console.log('添加')
                }
            },
            deep:true
        }
    },
    data(){
        var checkSpecification= (rule, value, callback) => {
            if (value&&value!=' ') {
                let arr = value.split("/")
                if(arr.length<=1){
                    callback(new Error("'规格转换'不符合数据格式要求"));
                }
                //查找每个arr是否有单位
                let err = false,unit=''
                arr.forEach(item=>{
                    let a = item.split("*")
                    a.length!=2?err = true:''
                    unit = a[1]
                })
                if(err){
                    callback(new Error("'规格转换'不符合数据格式要求"));
                }
                if(unit!=''){
                    this.form.unit = unit
                }
                //console.log(this.form)
            }
            callback();
        };
        return{
            pop:"为了方便入/出库和盘点自动转换数据，比如某药品规格为：1件/20瓶，每瓶500g，那么可写为‘1*件/20*瓶/500*g’(引号忽略)，且单位必须设置为‘g’，也可不填写。",
            user:{},
            jsonData:{
                method:'uploadImg'
            },
            actionUrl:this.API.api,
            btnText:"添加",
            loading:false,
            form:{
                cn_name:"",//中文名称
                en_name:"",//英文名称
                num:"",//编号
                price:0,//单价
                w_logo:"",//图片
                unit:"",//单位
                composition:"",//成分
                ratio:"",//物料配比
                usage_type:"",//使用方式,
                remarks:"",//备注
                classification:""
            },
            rules:{
                cn_name: [
                    { required: true, message: '请输入物品名称', trigger: 'blur' },
                    { min: 2, max: 15, message: '长度在 2 到 15 个字符', trigger: 'blur' }
                ],
                en_name: [
                    { required: true, message: '请输入物品名称', trigger: 'blur' },
                    { min: 2, max: 35, message: '长度在 2 到 35 个字符', trigger: 'blur' }
                ],
                num: [
                    { required: true, message: '请输入物品编号', trigger: 'blur' },
                    { min: 1, max: 15, message: '长度在 1 到 15 个字符', trigger: 'blur' }
                ],
                unit: [
                    { required: true, message: '请选择物品单位', trigger: 'blur' }
                ],
                classification: [
                    { required: true, message: '请选择物品类别', trigger: 'blur' }
                ],
                specification: [
                    { validator: checkSpecification, trigger: 'blur' }
                ]
            }
        }
    },
    created(){
        this.user = JSON.parse(sessionStorage.getItem("user"))
        this.actionUrl=this.actionUrl+'?token='+this.user.token
    },
    methods:{
        unichange(e){
            console.log(e)
            this.form.unit = e
            //console.log(this.form.unit)
        },
        close(cb){
            this.resetForm()
            return cb()
        },
        //获取数据详情
        getDetail(wid){
            let params = {
                method:"getGoodsDetail",
                wid
            }
            this.loading = true
            this.$ajax.post(this.API.api,params).then(({data})=>{
                    this.loading = false 
                    console.log(data)
                    if(data[0]){
                        this.form = data[0]
                    }
                }).catch(err=>{
                    this.loading = false 
                    console.log(err)
                    this.$message({
                        message: err.msg||this.CONST.FAIL_TIME_OUT,
                        type: 'warning'
                    });
                })
        },
        priceHandleChange(value) {
            console.log(value);
        },
        //输入编号
        inputNum(data){
             data=data.trim()
            this.form.num = data.replace(/[\W]/g,'')
        },
        //输入英文名称
        inputCnName(data){
            this.form.cn_name=data.trim()
            //this.form.cn_name = data.replace(/[\W]/g,'')
        },
        //输入英文名称
        inputEnName(data){
            data=data.trim()
            this.form.en_name = data.replace(/[\W]/g,'')
        },
        //提交
        onSubmit(form){
           //console.log(this.form)
            !form?form='form':''
           this.$refs.form.validate((valid) => {
               //console.log(valid)
               if(valid){
                   //console.log(valid)
                   let params = this.form
                   this.loading = true 
                   params.wid&&params.wid!=''?params.method = "updateGoods":
                   params.method = "addGoods"
                   console.log(params,params.unit)
                this.$ajax.post(this.API.api,params).then((res)=>{
                    this.loading = false 
                    this.$message({
                        message: res.msg||this.CONST.SUC_ADD,
                        type: 'success'
                    });
                    this.resetForm('form')
                    this.$emit("addSuc")
                }).catch(err=>{
                    this.loading = false 
                    console.log(err||err.data)
                    this.$message({
                        message: err.msg||this.CONST.FAIL_TIME_OUT,
                        type: 'warning'
                    });
                })
                   return 
               }else{
                   return false
               }
           })
        },
         //重置
        resetForm(form) {
            !form?form='form':''
            //this.$refs.form.resetFields();
            //重置不成功
            this.form={
                cn_name:"",//中文名称
                en_name:"",//英文名称
                num:"",//编号
                price:0,//单价
                w_logo:"",//图片
                unit:"",//单位
                composition:"",//成分
                ratio:"",//物料配比
                usage_type:"",//使用方式,
                remarks:"",//备注
                classification:""
            }
        },
        //编辑类别和单位
        editTy(num){
           this.$emit("editTy",num)
        },
        //输入中文名称
        change(e){
            // console.log(e)
            // console.log(vPinyin.chineseToPinYin(e))
            this.form.en_name = vPinyin.chineseToPinYin(e)
        }
        ,uploadFail(err){
            this.loading = false
            this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
            });
        },
        imgChange(){
            this.loading=true
        },
        uploadSuccess(response){
            //console.log(response)
            this.loading = false
            if(response.status==0){
            //suc
            this.form.w_logo = this.API.apiUrl+"admin/"+response.data 
            //console.log(this.form)
            }else{
            this.$message({
                message:response.msg,
                type: 'warning'
            });
            }
        },
    }
}
</script>
<style lang="less">
.add-cert{
    padding: 10px ;

    .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>